.error{
    color: red;
    font-size: 10px;

}

.notes {
    color: #0000FF;
    font-size: 13px;
    text-align: right;
    vertical-align: top;
}

.gitPrimeMetricsTable {
    margin-top: 7px;
}