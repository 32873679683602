.SortHoverStyle {
  color: black;
}

.SortStyle {
  color: grey;
}

.error {
  color: red;
  font-size: 10px;
}

.notes {
  color: #0000ff;
  font-size: 13px;
  text-align: right;
  vertical-align: top;
}

.pstyle {
  text-align: end;
  margin: 0em;
  width: fit-content;
}
.pstyle2 {
  text-align: end;
  margin: 0em;
  letter-spacing: 0.12px;
  width: fit-content;
}
