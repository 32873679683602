table.objectiveTable {
    font-size: x-small;
    width: 102%;
    border-spacing: 5px;
    text-align: center;
  }

table.sourceTable{
  font-size: x-small;
  position: relative;
  border-collapse: collapse;
  text-align: center;
  width: 100%;
}

thead.stickyHeader tr.subRow th{
  position: sticky;
  background-color: #00b0f0;
  top: 2.7rem; 
  color: "white";
  }

thead.stickyHeader tr.mainRow th{
    position: sticky;
    background-color: #00b0f0;
    top: 1.7rem;
    color: "white";
}
thead.stickyHeader tr.headerRow th{
  position: sticky;
  background-color: #00b0f0;
  top: 0rem;
  color: "white";
}

table.sourceTable tr th{
  border: 1px solid black;
}
table.sourceTable tr td{
  border: 1px solid black;
}
table.objectiveTable tr.mainRow{
  background-color: #00b0f0;
  color: ghostwhite;
}
table.objectiveTable th.head{
  background-color: #00b0f0;
  color: ghostwhite;
  font-size: large;
}
table.objectiveTable tr.subRow{
  font-size: "10px";
  background-color: #00b0f0;
  color: ghostwhite;
}
div.scroll {
  background-color: #ffff;
  max-width: 482px;
  max-height: 212px;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: left;
  padding: 20px;
}
