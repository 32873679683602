.table-responsive {
    max-height: 400px;
}

.table-mycontainer {
    margin-top: 10px;
}

.spinner-row {
    height: 25px;
    margin-top: -10px;
}

.footer-mycontainer {
    margin-top: 10px;
}

.freeze{
    position: sticky;
    left: 0px;
    background-color: rgb(255, 255, 255);
    z-index: 1;
}

.stickyCol{
    left: 0px;
}