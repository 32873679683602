.Card{
    margin: 6px ;
    height: auto;
    max-height: 250px;
    width: auto;
    max-width: 250px;
}

.Header{
    background-color: #8ea9db;
    border-radius:5px;
    padding:5px;
    text-align: center;
    width: 100%;
    margin-top: 10px;
}