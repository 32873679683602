.Added{
    background-color: #e0fae0;
}
.Remove{
    background-color: #ffebf6;
}
.Updated{
    background-color:#dbedfd ;
}

.NotFound{
    background-color: #F5F5F5;
}