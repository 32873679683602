.tableFont{
    font-size: small;
}
.nav1{
    background-color: #e6e1e1;
}
.tabPanStyle{
    margin-top: 0.5em;
    float: left;
    width:100% ;
}
.buttonStyle{
    float: left;
     margin-right: 5px;
}
.search{
    width: 70%;
    float: left;
    margin-right: 5px;
}
.searchText{
    border: 1px solid black;
    border-radius: 4px;
    height: 36px;
    width: 100%;
    float: left;
    margin-right: 10px;
}
.searchText:focus {
    outline: 0;
    border-color: #2684ff;
    box-shadow: 0 0 3px #2684ff;
    border-style: solid;
    border-width: 1px;
}

  