.smallDiv{
    margin: 4px ;
    text-align:center;
}
.count{
    margin: 0px;
}

.insideText{
    margin: 0px;
    font-size:12px;
}

.mainDiv{
    display: flex;
    margin: 0px;
    justify-content: space-evenly;
    flex-flow: row wrap
}