@import "./sass/overrides";
@import "./sass/modules";

.list-group .team-member {
    padding: 0;
    table {
        width: 100%;
    }
    .time-zone {
        padding: 0;
        width: 10px;
    }

    .member-info {
        padding: 12px 20px 12px 10px;
        background-color: white;
    }
}

.nav-tabs .nav-link {
  cursor: pointer;
}

.main-container {
  padding-left: 50px;
  padding-right: 50px;
}

.edit-team-modal-note {
  margin-bottom: 50px;
}

.sla-chart {
  height: 200px;
}

.productivity-row {
  height: 290px;
}

.quality-row {
  height: 385px;
}


.devops-row-left {
  height: 193px;
}

.devops-row-right {
  height: 386px;
}
.my-teams {
    .sprint-controls {
        display: inline-block;
        float: right;
    }

    .team-calendar {
        .available {
            background-color: rgb(128, 232, 109);
        }

        .not-employed-here {
            background-color: rgb(232, 232, 232);
        }

        .holiday {
            background-color: rgb(232, 109, 109);
        }

        .pto {
            background-color: rgb(109, 173, 232)
        }
    }

    .blockers {
        .status {
            display: inline-block;
            border-radius: 4px;
            padding: 6px;
        }

        .resolved .status {
            color: white;
            background-color: rgb(0, 191, 86);
        }

        .blocked .status {
            color: white;
            background-color: rgb(191, 0, 32);
        }
    }
}
.editor {
    div[role="textbox"] {
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        padding: 0.375rem 0.75rem;
    }
}