.reset{
    margin: 0px;
}
.position{
    flex-basis: 14%;
}
.positionSm{
    flex-basis: 13%;
}
.buttonStyle{
    margin-top: 5px;
    font-size: small;
    max-height: 5em;
}
.space{
    padding-right: 15px;
}
.pstyle{
 text-align:end;
 margin:0em;
 width: fit-content
}
.pstyle2{
    text-align:end;
    margin: 0em;
    letter-spacing: 0.12px;
    width: fit-content;
}
.overviewCard{
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;

}