.freeze {
    position: sticky;
    left: 0px;
    background-color: #ffffff;
}
.freezeReleaseNotesKey {
    position: sticky;
    left: 70px;
    background-color:#ffffff;
}
.freezeReleaseItemKey{
    position: sticky;
    left: 140px;
    background-color: #ffffff;
}
