.borderStyle{
    border-right: 1px solid black;
  }
  .navstyle{
    background-color: #e6e1e1;
  }
  .tabPanStyle{
    margin-top: 0.5em;
    float: left;
    width:100% ;
  }

  .headerStyle{
    color: ghostwhite;
    background-color: #00b0f0;

  }
  .freeze {
    position: sticky;
    left: 0px;
    background-color: #8ad6f0;
}
.widthStyle{
  width: 30px;
}
.textAlign{
  text-align: end;
}

.insideTableStyle{
  table-layout: fixed;
   width: 330px;
   margin: 5px auto;
}

.objective{
  width: 20%;
  border-right: 1px solid black;
}

.labelStyle{
  margin:0rem;
}

.clickable{
  color: blue;
}

.clickable:hover{
  color: blueviolet;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}